import * as Sentry from '@sentry/gatsby'
import { excludeGraphQLFetch } from 'apollo-link-sentry'

const isProduction = process.env.NETLIFY
  ? process.env.CONTEXT === 'production'
  : false

Sentry.init({
  dsn: 'https://c0948f390f2f44139cda940ddc05374d@o1228670.ingest.sentry.io/6374548',
  integrations: [Sentry.browserTracingIntegration()],
  enableTracing: false,
  beforeBreadcrumb: excludeGraphQLFetch,
  environment: isProduction ? 'production' : 'dev',
  release: process.env.COMMIT_REF,
  enabled: process.env.NODE_ENV === 'production'
})
